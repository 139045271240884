{
  "defaultLocale": {
    "value": "en",
    "baseHref": "/"
  },
  "availableLocales": [
    {
      "value": "cs",
      "baseHref": "/cs/"
    },
    {
      "value": "de",
      "baseHref": "/de/"
    },
    {
      "value": "es",
      "baseHref": "/es/"
    },
    {
      "value": "fr",
      "baseHref": "/fr/"
    },
    {
      "value": "hi",
      "baseHref": "/hi/"
    },
    {
      "value": "hr",
      "baseHref": "/hr/"
    },
    {
      "value": "it",
      "baseHref": "/it/"
    },
    {
      "value": "ka",
      "baseHref": "/ka/"
    },
    {
      "value": "kk",
      "baseHref": "/kk/"
    },
    {
      "value": "pl",
      "baseHref": "/pl/"
    },
    {
      "value": "pt",
      "baseHref": "/pt/"
    },
    {
      "value": "ro",
      "baseHref": "/ro/"
    },
    {
      "value": "ru",
      "baseHref": "/ru/"
    },
    {
      "value": "sr",
      "baseHref": "/sr/"
    },
    {
      "value": "sv",
      "baseHref": "/sv/"
    },
    {
      "value": "tr",
      "baseHref": "/tr/"
    },
    {
      "value": "uk",
      "baseHref": "/uk/"
    },
    {
      "value": "zh",
      "baseHref": "/zh/"
    },
    {
      "value": "en",
      "baseHref": "/"
    }
  ]
}
