import { Env } from '../app/dto/enum/env.enum';
import { IConfigDTO } from '../app/dto/config/config.dto';
import { profileDTOs } from './profiles';
import { version } from '../../package.json';
import { availableLocales, defaultLocale } from '../../locales.json';
import { host } from '../../indexing.json';

export const configDTO: IConfigDTO = {
  env: Env.PROD,
  api: 'https://api.ig4u.co',
  host: host,
  appVersion: version,
  recaptchaSiteKey: '6LdK6u4pAAAAAIzquc2-LyOdN7m3OJOdQHH6z-Ie',
  googleTagId: 'G-2R0CX2ZKES',
  defaultLocale: defaultLocale,
  availableLocales: availableLocales,
  sentry: {
    dsn: 'https://f620a7f21000366b7502fafc4f4f5f4f@o4507266554003456.ingest.de.sentry.io/4507270776225872',
    debug: false,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/ig4u\.co/,
      /^https:\/\/api\.ig4u\.co/,
    ],
  },
  examples: {
    profiles: profileDTOs,
  },
};
