import { IProfileDTO } from '../app/dto/config/profile.dto';
import { ProfileCategory } from '../app/dto/enum/profile-category.enum';

export const profileDTOs: IProfileDTO[] = [
  {
    username: 'fcbarcelona',
    color: '#1f2ade',
    category: ProfileCategory.SPORT,
    enabled: true,
  },
  {
    username: 'realmadrid',
    color: '#1f2ade',
    category: ProfileCategory.SPORT,
    enabled: true,
  },
  {
    username: 'mancity',
    color: '#1f2ade',
    category: ProfileCategory.SPORT,
    enabled: true,
  },
  {
    username: 'manchesterunited',
    color: '#1f2ade',
    category: ProfileCategory.SPORT,
    enabled: true,
  },
  {
    username: 'leomessi',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'cristiano',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'kimkardashian',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'kendalljenner',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'snoopdogg',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'kanyewest',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'irinashayk',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'davidbeckham',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'realdonaldtrump',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'google',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'netflix',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'spotify',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'hm',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'miumiu',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'sparkloworld',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'massimodutti',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'lifeatglovo',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'jacquemus',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'natgeo',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'nike',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'instagram',
    color: '#00df82',
    category: ProfileCategory.COMPANY,
    enabled: true,
  },
  {
    username: 'sashamgf',
    color: '#ff4400',
    category: ProfileCategory.PERSONAL,
    enabled: true,
  },
  {
    username: 'badgalriri',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'pivo_varov',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'zendaya',
    color: '#c3b3f1',
    category: ProfileCategory.BLOGGER,
    enabled: true,
  },
  {
    username: 'labrador_daisybrown',
    color: '#ffb3cf',
    category: ProfileCategory.ANIMAL,
    enabled: true,
  },
  {
    username: 'ducique',
    color: '#ffb3cf',
    category: ProfileCategory.ANIMAL,
    enabled: true,
  },
];
