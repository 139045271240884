{
  "name": "ig4u-ui",
  "version": "0.0.1-SNAPSHOT.220",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --localize",
    "watch": "ng build --watch --configuration local",
    "test": "ng test",
    "extract-i18n": "ng extract-i18n",
    "ssr:copy:proxy": "cp server/proxy.mjs dist/ig4u-ui/server",
    "ssr:copy:robots": "cp robots.txt dist/ig4u-ui/server",
    "ssr:copy:locales": "cp locales.json dist/ig4u-ui/server",
    "ssr:copy:indexing": "cp indexing.json dist/ig4u-ui/server",
    "serve:ssr:ig4u-ui": "node dist/ig4u-ui/server/proxy.mjs",
    "stats": "ng build --configuration prod --stats-json --named-chunks",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "prettier": "npx prettier --write ."
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.12",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/platform-server": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@angular/ssr": "^18.2.12",
    "@sentry/angular": "^8.38.0",
    "@sentry/cli": "^2.38.2",
    "etag": "^1.8.1",
    "express": "^4.21.1",
    "glob": "^11.0.0",
    "lightgallery": "^2.8.1",
    "mime-types": "^2.1.35",
    "ng-zorro-antd": "^18.2.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "xmlbuilder": "^15.1.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "18.4.0",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/schematics": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@angular/localize": "^18.2.12",
    "@types/etag": "^1.8.3",
    "@types/express": "^5.0.0",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^22.9.0",
    "@typescript-eslint/eslint-plugin": "8.14.0",
    "@typescript-eslint/parser": "8.14.0",
    "@types/mime-types": "^2.1.4",
    "eslint": "^9.15.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "googleapis": "^144.0.0",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "openai": "^4.72.0",
    "prettier": "^3.3.3",
    "prettier-eslint": "^16.3.0",
    "typescript": "~5.5.4"
  }
}
