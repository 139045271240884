import { Injectable } from '@angular/core';
import { Env } from '../dto/enum/env.enum';
import { IExamplesDTO } from '../dto/config/examples.dto';
import { configDTO } from '../../envs/env';
import { ILocaleDTO } from '../dto/config/locale.dto';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public get env(): Env {
    return configDTO.env;
  }

  public get api(): string {
    return configDTO.api;
  }

  public get host(): string {
    return configDTO.host;
  }

  public get recaptchaSiteKey(): string {
    return configDTO.recaptchaSiteKey;
  }

  public get examples(): IExamplesDTO {
    return configDTO.examples;
  }

  public get googleTagId(): string {
    return configDTO.googleTagId;
  }

  public get appVersion(): string {
    return configDTO.appVersion;
  }

  public get defaultLocale(): ILocaleDTO {
    return configDTO.defaultLocale;
  }

  public get availableLocales(): ILocaleDTO[] {
    return configDTO.availableLocales;
  }
}
